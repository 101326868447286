import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
export default {
  name: "feeRuleAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      carTypeList: [],
      formModel: {
        code: '',
        feeType: '按时收费',
        maxFee: 0,
        freeDuration: 0,
        freeFee: true,
        beginTime: '',
        endTime: '',
        timesFee: 0,
        items: [{
          seq: 1,
          duration: 0,
          fee: 0,
          editable: true
        }]
      },
      formRules: {
        code: [{
          required: true,
          message: '请输入规则编码',
          trigger: 'blur'
        }, {
          max: 16,
          message: '规则编码长度不能超过16',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z_0-9]+$/,
          message: '用户编码长度必须为字母、数字或_',
          trigger: 'blur'
        }],
        feeType: [{
          required: true,
          message: '请选择车辆类型',
          trigger: 'blur'
        }],
        freeDuration: [{
          required: true,
          message: '请输入免费时长',
          trigger: 'blur'
        }],
        beginTime: [{
          required: true,
          message: '请输入计费开始时间',
          trigger: 'blur'
        }],
        endTime: [{
          required: true,
          message: '请输入计费截止时间',
          trigger: 'blur'
        }],
        maxFee: [{
          required: true,
          message: '请输入封顶金额',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    feeTypeChange(feeType) {
      if (feeType === '按时收费') {
        this.formModel.items = [{
          seq: 1,
          duration: 0,
          fee: 0,
          editable: true
        }];
      } else {
        this.formModel.items = [];
      }
    },
    addItem() {
      this.formModel.items.push({
        seq: this.formModel.items.length + 1,
        duration: 0,
        fee: 0,
        editable: true
      });
    },
    deleteItem(delItem) {
      this.formModel.items.forEach(function (item, index, arr) {
        if (item.seq === delItem.seq) {
          arr.splice(index, 1);
        }
      });
      // 重新排序
      this.formModel.items.forEach((item, index) => {
        item.seq = index + 1;
      });
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.feeRule.update(this.formModel).then(callBack);
          } else {
            this.$api.business.feeRule.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.feeRule.detail(this.$route.query.id).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};